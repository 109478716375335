<script>
    import logo from '@/assets/images/logo/maxcluster-tag.svg';

    export default {
        name: "BasePage",
        methods: {
            configGet(key) {
                return window.Config.get(key);
            },
        },
        computed: {
            logoImage() {
                return logo;
            }
        }
    };
</script>