<template>
    <div class="row">
        <div class="col-12 mb-3">
            <h4>{{ $t('profile.personal_data.email_address') }}</h4>
        </div>
        <div class="col-6">
            <AtomInput :animated-label="false" :placeholder="$t('profile.personal_data.email')" v-model="currentEmail" disabled />
        </div>
        <div class="col-6">
            <AtomButton class="me-3" @click.prevent="showForm = true">
                {{ $t('profile.personal_data.change_email') }}
            </AtomButton>
        </div>
        <div class="col-12 mt-4" v-if="user?.emailUpdateRequest">
            <AtomAlert show variant="info" :dismissible="false">
                <p v-html="$t('profile.personal_data.change_email_information', {email: user.emailUpdateRequest})"></p>
            </AtomAlert>
        </div>
    </div>
    <MoleculeModal :close-text="$t('helper.actions.abort')" @close="reset" v-model="showForm" size="md">
        <template #modal-headline>{{ $t('profile.personal_data.change_email') }}</template>
        <template #content>
            <MoleculeForm @submit.prevent="updateEmail">
                <div class="row">
                    <div class="col-12">
                        <AtomInput :animated-label="false" type="password" :label="$t('profile.personal_data.password')" v-model="form.password" :error="v$.form.password.$error">
                            <template #error-message>{{ $t(v$.form.password.$errors[0].$message) }}</template>
                        </AtomInput>
                    </div>
                    <div class="col-12 mt-4">
                        <AtomInput :animated-label="false" :label="$t('profile.personal_data.new_email')" v-model="form.email" :error="v$.form.email.$error">
                            <template #error-message>{{ $t(v$.form.email.$errors[0].$message) }}</template>
                        </AtomInput>
                    </div>
                </div>
            </MoleculeForm>
        </template>
        <template #actions>
            <AtomButton @click.prevent="updateEmail">
                {{ $t('helper.actions.save') }}
            </AtomButton>
        </template>
    </MoleculeModal>
</template>
<script>
    import BaseComponent from "@/components/BaseComponent";
    import {ResponseService, userStore} from "@development/cluster-center";
    import {required, email} from '@development/cluster-center/src/utils/validators';

    export default {
        extends: BaseComponent,
        name: 'ChangeEmailForm',
        props: {
            email: String
        },
        data() {
            return {
                form: {
                    email: null,
                    password: null
                },
                currentEmail: this.email,
                showForm: false,
                vuelidateExternalResults: {
                    form: {
                        email: [],
                        password: [],
                    }
                },
                user: null
            };
        },
        mounted() {
            this.user = userStore();
        },
        methods: {
            async updateEmail() {
                this.vuelidateExternalResults.form.email = [];
                this.vuelidateExternalResults.form.password = [];

                this.v$.$reset();

                if (!await this.validate()) {
                    return;
                }

                return await this.user.updateEmail(this.form).then(async response => {
                    if (Array.isArray(response)) {
                        if (response[2] === 422) {
                            let errors = response[0].response.data.errors;

                            if (errors.email && errors.email[0] === 'The Email address has already been taken.') {
                                errors.email[0] = 'validations.email_taken';
                            }

                            if (errors.password && errors.password[0] === 'The password is invalid.') {
                                errors.password[0] = 'validations.invalid_password';
                            }

                            await this.addExternalErrors(response[0].response.data.errors, this.vuelidateExternalResults.form);
                        }

                        return;
                    }

                    ResponseService.globalNotify(this.$i18n.t('profile.personal_data.changed_email_success', {email: this.form.email}), 'info');

                    this.reset();
                });
            },
            reset() {
                this.form = {
                    email: null,
                    password: null
                };
                this.showForm = false;
                this.v$.$reset();
            }
        },
        watch: {
            email(val) {
                this.currentEmail = val;
            }
        },
        validations() {
            return {
                form: {
                    email: {required, email},
                    password: {required},
                }
            };
        }
    };
</script>