<template>
    <div class="row" v-bind="$attrs">
        <div class="col-12 mb-3">
            <h4>{{ $t('profile.notifications.title') }}</h4>
        </div>
        <div class="col-6">
            <label for="notification-toggle">{{ $t('profile.notifications.option') }}</label>
            <div class="d-flex mt-2">
                <AtomCheckbox
                    id="notification-toggle"
                    v-model="selected"
                    :options="options"
                    name="notifications"
                    switches
                />
            </div>
        </div>
    </div>
    <div class="row"></div>
</template>
<script>
    import {UserService, ResponseService} from "@development/cluster-center";

    export default {
        name: "NotificationsData",
        data() {
            return {
                selected: [],
                watchSelect: false
            };
        },
        mounted() {
            this.getNotifications();
        },
        computed: {
            options() {
                const i18n = window.Config.get('i18n');

                return [
                    { text: i18n.t(this.selected.length > 0 ? 'helper.status.activated' : 'helper.status.deactivated').toLowerCase(), value: true },
                ];
            }
        },
        methods: {
            async getNotifications() {
                const i18n = window.Config.get('i18n');

                return await UserService.showTechnicalNotifications().then(response => {
                    if (Array.isArray(response)) {
                        if (response[2] !== 401) {
                            ResponseService.danger(i18n.t(response[1], {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }));
                        }

                        return;
                    }

                    this.selected = [response.data.subscribed];
                });
            },
            async changeSubscription() {
                const i18n = window.Config.get('i18n');

                return await UserService[this.selected.length === 1 ?'subscribeNotifications' : 'unsubscribeNotifications']().then(response => {
                    if (Array.isArray(response)) {
                        if (response[2] !== 401) {
                            ResponseService.danger(i18n.t(response[1], {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email}));
                        }

                        this.selected = this.selected.length === 1 ? [] : [true];
                        return;
                    }

                    ResponseService.success(i18n.t(this.selected.length > 0 ? 'profile.notifications.activated_successfully' : 'profile.notifications.deactivated_successfully'));
                });
            }
        },
        watch: {
            selected() {
                if (this.watchSelect) {
                    this.changeSubscription();
                }

                this.watchSelect = true;
            }
        }
    };
</script>
<style>
    [for="notification-toggle"] {
        font-size: $ds-font-size-m;
    }
</style>