<template>
    <footer id="footer" class="mt-5">
        <div class="py-2" :class="containerClass">
            <div class="row d-flex pb4 footer-container">
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <div class="col">
                        <router-link to="/" class="navbar-brand me-4">
                            <AtomImage :size="174" :src="require('@/assets/images/logo/logo_white_slogan.svg')" alt="maxcluster-logo" draggable="false" />
                        </router-link>
                    </div>
                </div>
                <div class="col justify-content-end d-flex flex-column">
                    <div class="row justify-content-end mb-auto d-flex support-row align-items-start">
                        <div class="col">
                            <strong>{{ $t('app.footer.advice') }} (09:00-18:00 Uhr)</strong>
                        </div>
                        <div class="col">
                            <AtomTextLink :href="`tel:${$cc.advice.phone}`">
                                <strong><AtomIcon name="phone-handset-line" /> {{ $cc.advice.phone_formatted }}</strong>
                            </AtomTextLink>
                        </div>
                        <div class="col">
                            <AtomTextLink :href="`mailto:${$cc.advice.email}`">
                                <strong><AtomIcon name="envelope-line" /> {{ $cc.advice.email }}</strong>
                            </AtomTextLink>
                        </div>
                    </div>
                    <ul class="footer-nav d-flex justify-content-end align-items-end">
                        <li><AtomTextLink rel="noopener" href="https://maxcluster.de/impressum" target="_blank">{{ $t('app.footer.imprint') }}</AtomTextLink></li>
                        <li><AtomTextLink rel="noopener" href="https://knowledge.maxcluster.de" target="_blank">{{ $t('app.footer.knowledge_base') }}</AtomTextLink></li>
                        <li><AtomTextLink rel="noopener" href="https://maxcluster.de/datenschutz" target="_blank">{{ $t('app.footer.data_protection') }}</AtomTextLink></li>
                        <li><AtomTextLink rel="noopener" href="https://maxcluster.de/agb" target="_blank">{{ $t('app.footer.tos') }}</AtomTextLink></li>
                        <li><AtomTextLink rel="noopener" href="https://maxcluster.de/sla" target="_blank">{{ $t('app.footer.service_level_agreement') }}</AtomTextLink></li>
                        <li><AtomTextLink rel="noopener" href="https://maxcluster.de/richtlinien" target="_blank">{{ $t('app.footer.guidelines') }}</AtomTextLink></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    import BaseComponent from "@/components/BaseComponent.vue";

    export default {
        extends: BaseComponent,
        name: 'FooterBar',
        props: {
            containerClass: String
        }
    };
</script>
<style lang="scss" scoped>
    #footer {
        max-width: 100%;
        width: 100%;
        background: $footer-background;
        color: $white;
        position: relative;
        z-index: 5;
        flex-shrink: 0;

        .support-row {
            flex-wrap: nowrap;

            .col {
                flex: 0 0 auto;
                width: auto;
            }
        }

        a {
            color: $white;
            text-decoration: none;
            font-size: 14px;

            &:hover {
                color: $primary;
            }
        }
    }
    
    .footer-container {
        height: 65px;
    }

    .footer-nav {
        display: inline-block;
        list-style: none;
        margin-bottom: 0 !important;

        li {
            display: inline-block;

            &:not(:first-of-type) {
                margin-left: 1rem;
            }
        }
    }
</style>