import PartnerOverview from "@/components/Accounting/Partner/PartnerOverview.vue";
import PartnerSalesMaterial from "@/components/Accounting/Partner/PartnerSalesMaterial.vue";
import PartnerPayouts from "@/components/Accounting/Partner/PartnerPayouts.vue";
import PartnerContracts from "@/components/Accounting/Partner/PartnerContracts.vue";

export default [
    {
        path: "/invoices",
        name: "invoices",
        children: [{
            path: "",
            name: "invoices-overview",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Invoices/InvoicesPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.invoices')
            }
        }, {
            path: ":id",
            name: "invoices-show",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Invoices/InvoicesPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.invoices')
            },
        }, {
            path: ':id/details',
            name: 'invoices-details',
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Invoices/InvoiceDetailPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: (i18n, invoice) => {
                    if (!invoice) {
                        return i18n.t('app.page_title.invoices');
                    }

                    if (!invoice?.status && invoice.number) {
                        return i18n.t('app.page_title.invoice_show_without_status', {number: invoice?.number});
                    }

                    return i18n.t('app.page_title.invoice_show', {
                        number: invoice?.number,
                        status: i18n.t('accounting.invoice.status.' + invoice?.status?.toLowerCase())?.toLowerCase()
                    });
                },
            },
        }]
    },
    {
        path: "/partner",
        name: "partner",
        redirect: { name: "partner-overview" },
        children: [{
            path: ":partnerId?/:year?",
            name: "partner-overview",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/PartnerManagement/PartnerPage.vue"),
            props: {
                activeComponent: PartnerOverview,
            },
            meta: {
                middleware: ['authenticated', 'isPartnerManager'],
                title: i18n => i18n.t('app.page_title.partner_overview')
            }
        }, {
            path: "payments/:partnerId?/:customerId?",
            name: "partner-payouts",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/PartnerManagement/PartnerPage.vue"),
            meta: {
                middleware: ['authenticated', 'isPartnerManager'],
                title: i18n => i18n.t('app.page_title.partner_payments')
            },
            props: {
                activeComponent: PartnerPayouts,
            },
        }, {
            path: "material",
            name: "partner-sales-material",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/PartnerManagement/PartnerPage.vue"),
            meta: {
                middleware: ['authenticated', 'isPartnerManager'],
                title: i18n => i18n.t('app.page_title.partner_sales_material')
            },
            props: {
                activeComponent: PartnerSalesMaterial,
            },
        }, {
            path: "contracts/:partnerId?",
            name: "partner-contracts",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/PartnerManagement/PartnerPage.vue"),
            meta: {
                middleware: ['authenticated', 'isPartnerManager'],
                title: i18n => i18n.t('app.page_title.partner_contracts')
            },
            props: {
                activeComponent: PartnerContracts,
            },
        },
    ]},
    {
        path: "/customers/:customerId/edit",
        name: "customer-edit",
        component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/DashboardPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: i18n => i18n.t('app.page_title.dashboard')
        }
    },
    {
        path: "/contracts",
        name: "contracts",
        component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Contracts/ContractsListPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: i18n => i18n.t('app.page_title.contracts')
        },
        children: [{
            path: ":type/:id",
            name: "contracts-show",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Contracts/ContractsListPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.contracts')
            },
        }, {
            path: ":id/cluster/termination",
            name: "cluster-termination",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Contracts/ContractsListPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.contracts')
            },
        }, {
            path: ":id/cluster/termination/show",
            name: "cluster-termination-show",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Contracts/ContractsListPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.contracts')
            },
        }, {
            path: ":id/cluster/termination/:terminationId",
            name: "cluster-termination-show-id",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Contracts/ContractsListPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.contracts')
            },
        }, {
            path: ":id/cluster/termination/:terminationId/edit",
            name: "cluster-termination-edit",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Contracts/ContractsListPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.contracts')
            },
        }, {
            path: ":id/domain/termination",
            name: "domain-termination",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Contracts/ContractsListPage.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.contracts')
            },
        }
    ]
    }, {
        path: "/orders",
        name: "orders",
        component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Orders/OrdersPage.vue"),
        meta: {
            middleware: ['authenticated'],
            title: i18n => i18n.t('app.page_title.orders')
        },
        children: [{
            path: "",
            name: "orders-list",
            component: () => import(/* webpackChunkName: "Accounting" */ "@/components/Accounting/Orders/OrdersList.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.orders')
            },
        }, {
            path: 'cluster',
            name: "order-cluster",
            component: () => import(/* webpackChunkName: "OrderWizard" */ "@/components/Accounting/Orders/OrderWizard/OrderWizard.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.orders_cluster')
            },
            props: {
                type: 'cluster'
            }
        }, {
            path: "domain",
            name: "order-domain",
            component: () => import(/* webpackChunkName: "OrderWizard" */ "@/components/Accounting/Orders/OrderWizard/OrderWizard.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.orders_domain')
            },
            props: {
                type: 'domain'
            }
        }, {
            path: "certificate",
            name: "order-certificate",
            component: () => import(/* webpackChunkName: "OrderWizard" */ "@/components/Accounting/Orders/OrderWizard/OrderWizard.vue"),
            meta: {
                middleware: ['authenticated'],
                title: i18n => i18n.t('app.page_title.orders_certificate')
            },
            props: {
                type: 'certificate'
            }
        }]
    },
];