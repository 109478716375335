<template>
    <div class="notificationWrapper">
        <div v-if="unreadNotes > 0" class="unreadNotes position-absolute top-0 end-0 d-flex justify-content-center align-items-center">
            {{ unreadNotes }}
        </div>
        <div class="me-2 notificationButton">
            <AtomDropdown
                pill
                no-caret
                variant="link"
                toggle-class="text-decoration-none"
                style-variant="secondary"
                id="NotificationToggle"
            >
                <template #button-content>
                    <AtomIcon v-if="unreadNotes > 0" name="bell-solid"/>
                    <AtomIcon v-else name="bell-line"/>
                </template>
                <AtomDropdownItem class="readAllSection" v-if="unreadNotes > 0">
                    <div class="row">
                        <div class="col-8 d-flex align-items-center">
                            <p style="height: auto !important;">
                                {{ $t('notifications.read_all_text', {number: unreadNotes}) }}
                            </p>
                        </div>
                        <div class="col d-flex align-items-center justify-content-end">
                            <AtomButton variant="secondary" @click.prevent.stop="setReadAll">
                                {{ $t('notifications.read_all') }}
                            </AtomButton>
                        </div>
                    </div>
                </AtomDropdownItem>
                <AtomDropdownItem :data-id="notification.id" class="NotificationItem" v-for="notification in notifications.notificationList" :key="notification.id">
                    <div class="row">
                        <div class="notificationBar col-auto d-flex flex-column justify-content-center align-items-center pb-2">
                            <hr :class="notification.isRead === true ? 'isRead' : ''" style="display: inline-block; height: 95%; margin-bottom: 0; margin-top: 5px; margin-right: 2px;">
                            <AtomIcon class="unreadIcon" @click.prevent.stop="setRead(notification)" v-if="notification.isRead === false" name="info-standard-solid"></AtomIcon>
                            <AtomIcon class="read" v-else name="check-circle-solid"></AtomIcon>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col title-bar">
                                    <h5 class="mb-0">{{ $t(notification.title) }}</h5>
                                </div>
                                <div class="col-auto">
                                    <span class="date">
                                        {{  $d(notification?.date, 'short') }}                                    
                                    </span>
                                </div>
                            </div>
                            <div v-if="notification.image" class="row">
                                <div class="col mb-1">
                                    <AtomImage v-if="$i18n.locale === 'de'" :src="notification.image.de" />
                                    <AtomImage v-else :src="notification.image.en" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <template v-if="notification.isFeedback">
                                        <p v-html="$t(notification.message)"/>
                                    </template>
                                    <p v-else>
                                        {{ $t(notification.message) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="notification.link !== ''" class="row">
                        <div class="col d-flex justify-content-center readTextLink">
                            <AtomTextLink v-if="notification.external" rel="external nofollow" target="_blank" @click="setRead(notification)" :href="($i18n.locale === 'en' && notification.link_en) ? notification.link_en : notification.link">
                                <template v-if="notification.isFeedback">
                                    {{ $t('notifications.feedback_link') }}
                                </template>
                                <template v-else>
                                    {{ $t('notifications.link') }}
                                </template>
                                <AtomIcon name="caret-line-right"></AtomIcon>
                            </AtomTextLink>
                            <AtomTextLink v-else @click="setRead(notification)" :to="{name: notification.link}">
                                {{ $t('notifications.link') }}
                                <AtomIcon name="caret-line-right"></AtomIcon>
                            </AtomTextLink>
                        </div>
                    </div>
                </AtomDropdownItem>
                <div class="bottomSplit">
                </div>
            </AtomDropdown>
        </div>
    </div>
</template>
<script setup>
    import { notificationStore } from '@/stores/notificationStore';
    import { computed, onMounted } from 'vue';

    const notifications = notificationStore();

    notifications.updateNotifications();

    const unreadNotes = computed(() => {
        let count = 0;
        notifications.notificationList.forEach( item => {
            if (item.isRead === false) {
                count = count + 1;
            }
        });

        return count > 9 ? '9+' : count;

    });

    function notificationInViewport(element) {
        let rect = element.getBoundingClientRect();
        let html = document.documentElement;
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || html.clientHeight) &&
            rect.right <= (window.innerWidth || html.clientWidth)
        );
    }


    onMounted(() => {
        const notificationContainer = document.querySelector('#NotificationToggle ul');
        let bottomSplit = document.querySelector('.bottomSplit');
        notificationContainer.addEventListener('scroll', function () {
            setMultipleRead();
            if (Math.abs(notificationContainer.scrollHeight - notificationContainer.clientHeight - notificationContainer.scrollTop) <= 1) {
                bottomSplit.classList.add('hidden');
            } else {
                bottomSplit.classList.remove('hidden');
            }
        });
    });

    function setMultipleRead() {
        const notificationsItems = document.getElementsByClassName('NotificationItem');
        for (let index = 0; index < notificationsItems.length; index++) {
            if (notificationInViewport(notificationsItems[index]) === true) {
                notifications.notificationList.forEach( item => {

                    if (item.id === parseInt(notificationsItems[index].firstChild.dataset.id)) {
                        if (item.link === '') {
                            item.isRead = true;
                        }
                    }

                });
            }
        }
    }

    function setReadAll() {
        notifications.notificationList.forEach(item => {
            item.isRead = true;
        });
    }


    function setRead(notification) {
        notification.isRead = true;
    }


</script>
<style lang="scss">
    .notificationButton {
        border-radius: 20% !important;
        font-size: $ds-font-size-m;
        cursor: pointer;
        padding: 0 .5rem;
        height: auto !important;

        .readTextLink {
            font-size: $ds-font-size-s;
            font-weight: 600;
        }

        .unreadIcon {
            transition: .3s;
            cursor: pointer;
        }

        .notificationBar {
            max-width: 44px;

            .read {
                color: $disabled !important;
                font-size: 27px !important;
                margin-bottom: -5px !important;
                margin-top: -4px !important;
            }

            .atom-icon {
                font-size: 20px;
                color: $primary;
                margin-top: -2px;
            }
        }

        .title-bar {
            max-width: 75%;
            white-space: break-spaces;
        }

        img {
            width: 100% !important;
            border: 1px solid $secondary-light;
        }

        .date {
            font-size: $ds-font-size-xs;
        }

        p {
            width: 100% !important;
            height: 100% !important;
            white-space: initial;
            margin-bottom: 0;
            font-size: $ds-font-size-s;
        }

        hr {
            border-left: 3px solid;
            border-color: $primary;
            z-index: 20;
            width: 1px;
            position: relative;
            max-height: 100% !important;
        }

        .isRead {
            border-left: 3px solid $disabled;
            height: 97% !important;
        }

        .hidden {
            visibility: hidden;
            opacity: 0;
        }

        .bottomSplit {
            position: sticky;
            width: 100%;
            bottom: 0;
            left: 0;
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            height: 50px;
            z-index: 100;
            margin-top: -50px;
            cursor: default;
            transition: all ease-in-out .3s;
        }

        .atom-text-link {
            cursor: pointer !important;
        }

        .atom-dropdown .dropdown-item {
            border-bottom: 1px solid $secondary-light;
            cursor: default;
            padding-right: 22px;
        }

        .atom-dropdown .dropdown-item:hover {
            background: $white;
            color: $secondary;
        }

        .atom-dropdown .btn-group.secondary > button,
        .atom-dropdown .dropdown.secondary > button {
            padding: 0;

            span.atom-icon {
                margin-right: 0;
                padding: 0 .5rem;
                font-size: 1.9rem;
                margin-top: -3px;
            }
        }

        .atom-dropdown ul.dropdown-menu {
            height: 60vh;
            max-height: unset !important;
            width: 500px;
        }

    }

    .unreadNotes {
        border-radius: 100% !important;
        font-size: 1rem;
        color: $white;
        background-color: $primary;
        width: 26px;
        height: 26px;
        z-index: 10;
        margin-right: 12px;
    }

    .notificationWrapper {
        position: relative;

        .unreadNotes  {
            pointer-events: none !important;
        }
    }

    .NotificationItem {
        .unreadIcon:hover {
            transform: scale(1.1);
        }
    }

    #NotificationToggle {
        .dropdown-item:focus {
            background-color: $white;
        }
    }
</style>