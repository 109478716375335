<template>
    <div class="row center">
        <div class="col-8 message">
            <AtomImage class="mb-4" :size="400" :src="logoImage" alt="maxcluster-logo" draggable="false" v-if="!hideLogo && !userStore.isLoggedIn" />
            <div class="d-flex">
                <h2 class="" v-html="$t('errors.503_title')"></h2>
            </div>
            <slot>
                <p class="lead" v-html="$t('errors.503_default_message')"></p>
            </slot>
        </div>
        <div class="col-4" id="alpaka">
            <svg id="alpaka" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" style="enable-background:new 0 0 1000 1000;" xml:space="preserve" height="100vh">
                <g>
                    <path class="st0" d="M565.7,695.62c-4.12,12.52-4.62,37.16-1.23,60.2c2.7,1.1,4.62,3.95,4.62,7.29c0,0.25-0.01,0.5-0.03,0.74
		c0.57-0.19,1.17-0.29,1.79-0.29c3.56,0,6.45,3.35,6.45,7.48c0,1.33-0.3,2.57-0.82,3.65c1.95,1.45,3.16,3.47,3.16,5.7
		c0,1.73-0.72,3.32-1.96,4.63c1.22,1.6,1.96,3.64,1.96,5.85c0,3.8-2.16,7.07-5.26,8.54c2.65,2.31,4.38,6.14,4.38,10.47
		c0,7.05-4.59,12.77-10.24,12.77c-0.88,0-1.73-0.14-2.55-0.4c-11.12,35.61-14.16,93.13-14.16,93.13l-18.12,0.15l-1.79-98.39
		c-0.94-1.17-1.5-2.7-1.5-4.36c0-1.82,0.68-3.47,1.78-4.69l-0.04-0.29c-0.49,0.22-1.01,0.34-1.55,0.34c-3.2,0-5.8-4.22-5.8-9.42
		c0-4.8,2.21-8.76,5.06-9.35l-0.06-0.47c-1.77-1.72-2.88-4.18-2.88-6.91c0-0.49,0.04-0.96,0.1-1.43c-2.76-1.54-4.7-5.12-4.7-9.28
		c0-0.28,0.01-0.55,0.03-0.82c-0.61,0.19-1.25,0.29-1.91,0.29c-3.56,0-6.45-2.91-6.45-6.51c0-0.12,0-0.25,0.01-0.37
		c-1.77-2.59-2.88-6.26-2.88-10.33c0-2.19,0.32-4.26,0.89-6.11l-14.34-27.55L565.7,695.62z"></path>
                    <path id="tail" class="st1" d="M634.42,619.83c-0.71-4.48,0.84-8.71,1.72-12.8c3.47-16.09-0.22-30.29-8.47-42.9
		c-5.16-7.9-12.43-13.58-21.02-15.83c-7.54-1.97-9.16-8.59-10.7-15.61c1.79,3.32,3.52,3.63,6.05,0.59
		c5.64-6.79,12.35-11.96,20-15.33c5.04-2.22,10.16-2.6,15.23,0.11c6.5,3.47,8.83,9.25,6.9,17.5c-0.43,1.83-1.18,3.57-1.93,5.27
		c-1.57,3.57-0.37,4.81,2.8,5.25c7.84,1.1,15.3,3.58,22.07,8.49c3.94,2.86,6.59,6.55,5.8,12.06c-0.75,5.17-4.08,7.54-8.36,8.83
		c-4.9,1.48-4.91,1.75-1.71,7.01c4.93,8.11,1.95,18.56-5.48,20.45c-5.09,1.29-10.05,3.3-15.3,3.39c-3.18,0.05-4.79,1.42-5.08,5.19
		C636.71,614.45,636.27,617.47,634.42,619.83z"></path>
                    <path class="st0" d="M496.19,493.7c1.56-0.77,3.27-1.19,5.07-1.19c3.58,0,6.82,1.68,9.16,4.38c2.81-4.21,7.23-6.92,12.21-6.92
		c2.91,0,5.63,0.93,7.95,2.54c2.46-2.16,5.52-3.44,8.85-3.44c5.83,0,10.86,3.92,13.19,9.6c2.11-2.46,5.05-3.99,8.3-3.99
		c4.21,0,7.9,2.56,9.95,6.41c1.82-1.08,3.88-1.69,6.07-1.69c6.1,0,11.23,4.74,12.76,11.19c0.88-0.18,1.78-0.27,2.71-0.27
		c5.82,0,10.89,3.7,13.53,9.15c1.35-0.51,2.79-0.78,4.28-0.78c6.84,0,12.51,5.71,13.61,13.2c0.99-0.32,2.04-0.49,3.13-0.49
		c6.44,0,11.66,5.99,11.66,13.39c0,1.24-0.15,2.44-0.42,3.58c1.56,0.43,3,1.22,4.24,2.29c11.62,0.74,23.29,1.54,34.44,24.02
		c12.66,25.52,4.29,47.76-7.23,44.12l-0.12,0.76c2.84,2.33,4.65,5.83,4.65,9.74c0,5.16-3.15,9.6-7.67,11.59
		c3.52,2.42,5.85,6.64,5.85,11.44c0,6.37-4.11,11.72-9.66,13.2c2.42,2.36,3.92,5.62,3.92,9.23c0,5.53-3.52,10.26-8.49,12.14
		c1.41,2.5,2.24,5.53,2.24,8.8c0,6.98-3.76,12.87-8.92,14.76c1.19,1.78,1.89,3.96,1.89,6.33c0,5.26-3.47,9.64-8.06,10.58
		c5.08,1.48,5.54,6.76,5.54,13.05c0,3.4-1.1,6.5-2.91,8.87c2.69,1.84,4.47,5.02,4.47,8.63c0,3.48-1.65,6.55-4.18,8.42
		c2.18,1.46,3.65,4.24,3.65,7.43c0,2.48-0.89,4.71-2.31,6.27c4.13,0.72,7.26,4.2,7.26,8.39c0,3.38-2.04,6.3-5.01,7.68
		c3.99,0.48,7.09,4.29,7.09,8.92c0,4.96-3.56,8.98-7.95,8.98c-0.22,0-0.44-0.01-0.66-0.03c-4.84,30.21-18.18,109.06-18.18,109.06
		l-18.12,0.15l-0.87-102.31l0-0.01c-0.66,0.21-1.36,0.32-2.07,0.32c-4.8,0-8.68-4.99-8.68-11.14c0-4.18,1.79-7.82,4.45-9.72
		c-1.77-1.64-2.88-4.02-2.88-6.66c0-2.22,0.79-4.26,2.09-5.82c-3.09-1.6-5.2-4.81-5.2-8.52c0-0.27,0.01-0.54,0.03-0.8
		c-3.16-0.77-5.52-3.88-5.52-7.6c0-1.55,0.41-2.98,1.11-4.2c-0.11,0-0.22,0.01-0.33,0.01c-4.75,0-8.6-4.29-8.6-9.57
		c0-0.44,0.03-0.87,0.08-1.29c-0.41,0.06-0.83,0.09-1.25,0.09c-5.54,0-10.03-5.16-10.03-11.52c0-0.78,0.07-1.55,0.2-2.28
		c-2.59-0.24-4.86-1.55-6.32-3.47c-1.3,4.78-5.71,8.3-10.94,8.3c-1.58,0-3.09-0.32-4.46-0.9c-1.65,2.37-4.34,3.91-7.38,3.91
		c-2.38,0-4.55-0.95-6.17-2.5c-0.8,0.24-1.61,0.48-2.43,0.71c-0.96,4.8-4.57,8.36-8.87,8.36c-2.57,0-4.9-1.28-6.55-3.33
		c-0.55,6.72-6.5,12-13.77,12c-4.71,0-8.87-2.22-11.36-5.61c-1.36,2.84-0.46,4.72-3.19,4.72"></path>
                    <path class="st0" d="M646,703.3c-7.3,22.2,3.84,82.54,4.79,104.43c1.01,23.34-18.67,115.33-18.67,115.33l-18.12,0.15l-0.87-102.31
		L608,779.61l-30.03-52.06L646,703.3z"></path>
                    <path class="st2" d="M615.37,917.31c-4.22,4.54-11.94,14.28-11.81,17.42c0.13,3.14,8.21,2.69,8.21,2.69s2.48,2.09,6.77,3.29
		c4.3,1.2,13.16,1.5,15.24-3.44c2.08-4.94-1.18-15.24-1.18-15.24C625.11,926.13,617.22,920.6,615.37,917.31z"></path>
                    <path class="st2" d="M535.02,909.88c-4.22,4.54-9.19,13.13-9.06,16.27c0.13,3.14,8.21,2.69,8.21,2.69s2.47,2.09,6.77,3.29
		c4.3,1.2,13.16,1.5,15.24-3.44c2.08-4.94-2.83-15.24-2.83-15.24C542.76,915.45,535.02,909.88,535.02,909.88z"></path>
                </g>
                <path class="st3" d="M456.87,744.42c0.2,26.37-2.5,62.89-1.45,85.72c1.12,24.33-2.98,113.78-2.98,113.78l-20.03,0.16l-1.69-94.58
	c-2.33-1.64-3.87-4.49-3.87-7.72c0-1.68,0.41-3.26,1.14-4.61c-0.73-1.35-1.14-2.92-1.14-4.6c0-2.55,0.96-4.86,2.52-6.52
	c-2.73-2.02-4.53-5.42-4.53-9.28c0-0.13,0-0.25,0.01-0.38c-4.55-3.51-7.52-9.27-7.52-15.78c0-4.51-7.14-22.15-4.76-25.44
	l-6.59-33.79L456.87,744.42z"></path>
                <path class="st3" d="M569.19,749.58l0,0.3c4.68,1.45,8.21,8.19,8.21,16.28c0,6.61-2.36,12.32-5.77,14.98
	c0.75,2.12,1.17,4.59,1.17,7.23c0,4.3-1.13,8.15-2.92,10.76c1.21,2.2,1.95,5.11,1.95,8.3c0,3.4-0.84,6.49-2.2,8.73
	c2.48,1.97,4.17,6,4.17,10.65c0,5.39-2.28,9.95-5.41,11.43c-1.23,27.35-9.77,103.16-9.77,103.16l-16.05,0.45l1.08-27.65l-7.58-88.73
	l-1.41-34.5c0,0,2.75-11.19-2.97-28.43c-1.97-5.94,0.04-12.34-3.75-18.13L569.19,749.58z"></path>
                <path class="st4" d="M449.84,123.72l-0.36-0.51c7.86-5.66,11.74-4.89,13.67-2.2c1.72,2.38,1.84,6.26,1.9,8.42
	c0.01,0.38-0.01,0.69-0.02,0.9c0.72-0.58,3.91-1.62,6.85-1.65c1.19-0.01,2.34,0.14,3.3,0.53c0.99,0.41,1.77,1.08,2.13,2.12
	c0.65,1.82,0,4.75-3.04,9.34l-0.52-0.34c2.91-4.39,3.56-7.14,2.97-8.78c-0.3-0.85-0.95-1.4-1.78-1.75
	c-0.87-0.36-1.95-0.49-3.06-0.49c-3.02,0.02-6.24,1.02-6.43,1.43c-0.22,0.47-0.44,0.63-0.69,0.51c-0.3-0.13-0.32-0.81-0.35-1.81
	c-0.06-2.09-0.18-5.84-1.78-8.07C460.9,118.96,457.3,118.34,449.84,123.72z M465.01,130.66c0,0.02,0.01,0.03,0.01,0.03
	C465.08,130.72,465.06,130.7,465.01,130.66z"></path>
                <path class="st4" d="M505,148.93c5.27-6.81,9.17-6.89,11.94-4.75c2.92,2.25,4.66,6.92,5.42,8.96c0.21,0.55,0.35,0.93,0.44,1.05
	l0.21,0.27l0.25-0.23c0.02-0.02,6.76-6.34,12.03-2.27c1.86,1.43,1.7,5.67,1.58,8.69c-0.03,0.67-0.05,1.28-0.05,1.76h0.63
	c0-0.52,0.02-1.1,0.05-1.73c0.12-3.15,0.29-7.58-1.83-9.2c-4.99-3.85-11.07,0.8-12.52,2.02l-0.22-0.57
	c-0.78-2.09-2.57-6.87-5.63-9.23c-3.04-2.35-7.25-2.34-12.82,4.86L505,148.93z"></path>
                <path class="st3" d="M385.3,702.31c-9.25-0.27-18.89-0.75-23.83-4.04c-4.95-3.3-2.55-11.78-1.65-13.19
	c-3.29-2.8-11.6-4.27-11.6-8.95c0-3.96,1.5-7.57,3.95-10.29c-0.21,0.01-0.42,0.01-0.64,0.01c-8.6,0-15.58-5.86-15.58-13.09
	c0-2.25,0.67-4.36,1.86-6.21c-3.93-2.4-6.5-6.37-6.5-10.86c0-2.73,0.5-7.85,2.13-9.95c-3.58-2.94-9.89-4.73-9.89-9.89
	c0-7.04,2.15-11.59,8.13-13.76c-0.22-0.76-1.54-2.72-1.54-6.02s5.49-2.32,4.95-4.95c-0.54-2.63-3.3-1.65-3.3-8.24
	c0-5.77,7.17-7.19,12.48-7.19l0.09,0c0.16-0.29,0.34-0.58,0.52-0.86c-3.68-2.7-6.5-8.47-6.5-13.38c0-4.86,7.92-10.48,11.54-13.19
	c-4.53-3.38-8.24-10.39-8.24-16.48c0-8.78,8.31-13.45,16.49-15.39c-4.63-3.41-8.25-12.64-6.6-19.23c0-7.79,13.01-12.01,19.78-14.84
	c-3.35-2.81-6.59-13.19,1.65-19.78c3.3-6.59,18.91-5.97,26.28-7.08c-4.3-2.83-7.14-7.7-7.14-13.23c0-5.58,2.88-10.48,7.24-13.3
	c-2.27-2.38-3.66-5.59-3.66-9.14c0-3.06,1.04-5.88,2.79-8.12c-4.92-3.07-8.19-8.54-8.19-14.77c0-8.16,5.62-15.01,13.2-16.89
	c-3.76-2.49-6.24-6.76-6.24-11.61c0-5,2.64-9.39,6.6-11.84c-4.72-2.5-7.93-7.47-7.93-13.18c0-5.05,2.51-9.51,6.35-12.21
	c-4.91-2.24-8.32-7.19-8.32-12.93c0-5.27,2.87-9.88,7.14-12.33c-3.94-2.93-6.5-7.62-6.5-12.91c0-4.1,1.54-7.84,4.06-10.68
	c-5.04-3.24-8.37-8.9-8.37-15.34c0-5.37,2.32-10.19,6.01-13.53c-2.01-2.7-3.19-6.05-3.19-9.67c0-4.11,1.53-7.86,4.04-10.72
	l-0.03-0.32c-4.72-2.18-7.99-6.95-7.99-12.49c0-3.59,1.37-6.85,3.62-9.3c-2.93-2.52-4.78-6.26-4.78-10.42
	c0-3.52,1.32-6.73,3.49-9.16c-3.17-2.91-5.15-7.08-5.15-11.72c0-8.22,6.23-14.98,14.23-15.82c-1.87-2.8-2.96-6.16-2.96-9.78
	c0-9.75,7.9-17.65,17.65-17.65c1.7,0,3.34,0.24,4.89,0.69c1.04-1.05,2.1-2.07,3.16-3.06c-1.58-2.45-2.5-5.36-2.5-8.49
	c0-8.5,6.77-15.42,15.21-15.65c2.75-4.76,7.89-7.96,13.78-7.96c4.5,0,8.57,1.87,11.46,4.88c1.67-3.47,5.22-5.87,9.34-5.87
	c4.12,0,7.68,2.41,9.35,5.89c2.76-3.01,6.72-4.9,11.12-4.9c5.72,0,10.7,3.19,13.26,7.88c1.85-0.91,3.93-1.42,6.13-1.42
	c7.69,0,13.92,6.23,13.92,13.92c0,1.14-0.14,2.24-0.39,3.3c5.24,4.01,10.08,8.8,14.41,14.33c0.8-0.13,1.63-0.2,2.47-0.2
	c8.38,0,15.17,6.79,15.17,15.17c0,3.86-1.44,7.38-3.81,10.05l0.11,0.3c6.33,1.07,11.16,6.59,11.16,13.23
	c0,4.02-1.76,7.62-4.56,10.08c5.77,0.84,10.2,5.81,10.2,11.81c0,4.74-2.77,8.84-6.78,10.76c3.5,2.28,5.81,6.22,5.81,10.7
	c0,5.52-3.51,10.23-8.42,12c-0.01,0.76-0.02,1.53-0.04,2.29c5.81,3.18,9.76,9.35,9.76,16.44c0,5.76-2.61,10.92-6.7,14.35
	c5.17,2.76,8.69,8.21,8.69,14.48c0,8.36-6.25,15.25-14.33,16.28l-0.11,1.92c7.74,3.62,13.11,11.47,13.11,20.58
	c0,6.76-2.95,12.82-7.63,16.98c2.82,2.08,4.65,5.43,4.65,9.21c0,6.31-5.12,11.43-11.43,11.43c-0.35,0-0.7-0.02-1.05-0.05
	c7.95,2.1,13.81,9.34,13.81,17.95c0,7.07-3.95,13.22-9.77,16.35c4.83,2.56,8.12,7.63,8.12,13.48c0,6.1-3.58,11.36-8.75,13.8
	c7.22,1.2,12.73,7.48,12.73,15.04c0,5.5-2.91,10.32-7.28,13c7.71,1.99,13.41,9,13.41,17.33c0,2.82-0.65,5.48-1.81,7.85
	c0.46-0.04,0.93-0.06,1.4-0.06c8.33,0,15.08,6.75,15.08,15.08c0,2.38-0.55,4.63-1.53,6.63c0.5-0.06,1.01-0.09,1.53-0.09
	c6.8,0,12.43,4.99,13.43,11.51c0.52-0.05,1.04-0.07,1.57-0.07c7.97,0,10.39,8.88,12.03,16.35c0,0,11.54,1.65,11.54,8.24
	s-8.24,3.3-8.24,8.24c0,4.95,0,6.59,0,6.59s6.7-3.3,7.47,3.3c0.77,6.59-7.47,8.24-7.47,8.24s6.59,4.95,6.59,9.89
	c0,4.95-8.24,8.24-8.24,8.24s6.59,3.3,6.59,13.19s-11.54,8.24-11.54,8.24s11.54,8.24,9.89,16.48c-1.65,8.24-9.89,9.89-9.89,9.89
	s8.23,8.14,5.77,18.08c-2.47,9.94-12.36,9.94-12.36,9.94s18.13,4.95,16.48,14.84c-1.65,9.89-14.84,8.24-14.84,8.24
	s16.38,10.37,13.96,19.2s-12.31,8.82-12.31,8.82s6.59,9.89,4.95,18.13c-1.65,8.24-14.84,4.95-14.84,4.95s16.48,13.19,9.89,19.78
	c-6.59,6.59-16.48,6.59-16.48,6.59s18.13,8.24,11.54,14.84c-6.59,6.59-14.84,3.3-14.84,3.3"></path>
                <path class="st2" d="M542.91,932.54c-4.09,5.03-8.49,14.17-8.36,17.65c0.13,3.47,7.95,2.98,7.95,2.98s2.4,2.32,6.56,3.65
	c4.16,1.33,12.74,1.66,14.76-3.81c2.02-5.46-4.22-15.23-4.22-15.23C552.34,942.31,544.7,936.18,542.91,932.54z"></path>
                <path class="st2" d="M433.24,931.76c-4.45,5.92-8.61,13.83-8.48,17.92c0.14,4.1,8.65,3.51,8.65,3.51s2.61,2.73,7.14,4.28
	c4.53,1.56,13.86,1.95,16.06-4.48c2.2-6.44-4.05-16.57-4.05-16.57C441.4,939.02,433.24,931.76,433.24,931.76z"></path>
                <path class="st5" d="M437.32,701.38c-8.53-6.76-8.49-15.19-3.4-25.2c2.2-4.32,2.3-6.54-0.73-10.16c-3.84-4.57-7.45-9.71-4.2-16.39
	c0.64-1.32,0.71-3.45,2.44-3.31c2.13,0.17,1.18,2.33,1.16,3.57c-0.07,4.58-0.12,8.81,3.8,12.44c2.86,2.65,7.57,3.71,8.7,7.56
	c1.4,4.76-4.19,6.81-5.7,10.74c-3,7.82-0.17,14.96,7.27,16.37c3.73,0.71,6.95-1.39,10.35-2.51c4.65-1.52,8.7-8.14,11.95-6.68
	c4.08,1.83,2.56,9.04,4.05,13.76c3.26,10.32,12.84,15.27,22.51,12.44c2.19-0.64,4.71-5.31,6.62-1.58c1.42,2.77-3.53,3.13-5.22,5.06
	c-1.5,1.72-3.79,1.58-5.91,1.77c-10.9,1-18.91-3.68-23.52-13.22c-2.31-4.8-4.57-5.93-8.93-3.27c-3.24,1.98-6.83,2.25-9.89,2.08
	C441.25,704.82,441.42,704.62,437.32,701.38z"></path>
                <path class="st6" d="M495.15,224.97c-1.9-4.33-2.23-8.26-2.57-13.88c-0.15-2.49-9.53-10.65-12.19-8.07
	c-4.78,4.65-11.03,5.67-16.96,6.62c-4.31,0.69-7.06,1.46-7.52,6.17c-0.11,1.11-0.83,2.23-1.96,2.85c1.49-6.05,1.33-12.21,1.53-18.38
	c0.18-5.84,0.39-5.69-5.53-6.03c-7.34-0.43-23.08-3.84-23.74-4.1c-4.82-1.9-4.4-2.96,0.24-7.75c0.63-0.65,1.34-1.25,2.04-1.82
	c1.68-1.38,3.31-3.52,5.66-2.42c0.05,0.02,0.08,0.08,0.06,0.13c-0.27,0.82-2.25,2.54-3.29,3.99c-2.07,2.88-1.75,3.74,1.59,5.12
	c2.5,1.03,28.75,3.8,40.06,1.75c6.49-1.23,8.85,3.35,12.93-2.08c1.03-1.91,1.51-5.61,4.27-4.76c2.46,0.76,1.02,4.19,1.16,6.43
	c0.18,2.85-0.08,5.72,0.04,8.58c0.17,4.17,0.64,4.59,4.69,2.75c5.41-2.45,10.37-5.7,14.72-9.85c0.82-0.78,1.64-3.07,3.11-1.53
	c1.03,1.07-0.3,2.62-1.31,3.66c-3.16,3.23-6.01,6.9-10.17,8.88c-5.79,2.77-6.86,7.22-7,13.31
	C494.8,223.65,495.46,215.93,495.15,224.97z"></path>
                <path class="st8" d="M494.4,212.83c-0.12-3.51-2.26-5.81-5.01-8.39c-5.05-4.73-9.59-5.26-15.08-1.33c-1.92,1.37-5.39,3.4-7.68,3.54
	c-8.45,0.52-10.02,4.14-13.36,12.02c-1.6,3.71-3.35,7.36-4.75,11.14c-1.55,4.2-3.65,8.18-4.1,12.83
	c-1.64,16.98,10.43,29.57,22.25,34.88c2.58,1.16,5.05,1.01,7.62,0.15c7.34-2.46,13.07-6.83,16.85-13.52
	c0.38-0.68,0.81-1.34,1.32-1.93c2.83-3.27,2.99-6.23,3.7-12.08C497.1,246.27,496.65,226.57,494.4,212.83z"></path>
                <path class="st9" d="M469.87,215.72c5.98-0.08,8.89,1.68,9.09,5.51c0.2,3.84-6.07,13.03-8.74,12.82c-2.6-0.21-8.29-8.76-8.31-12.48
	C461.9,217.63,464.39,215.8,469.87,215.72z"></path>
                <g id="eye">
                    <path class="st10" d="M437.06,215.8c-2.85,0.05-6.31-2.67-6.72-5.4c-0.1-0.66-0.03-1.47,0.29-2.04c1.49-2.67,10.65-2.83,12.39-0.22
	C444.89,211.33,439.99,215.74,437.06,215.8z"></path>
                    <path class="st7" d="M521.04,209.87c-0.05,4.27-2.53,5.56-6.28,5.42c-3.54-0.13-7.27-1.93-7.1-5.81c0.16-3.64,3.23-6.92,6.61-7.06
	C517.96,202.27,521.09,205.73,521.04,209.87z"></path>
                </g>
                <path class="st11" d="M556.91,582.64c2.9-5,2.85-10.25,1.24-15.71c-2.74-9.28-2.48-9.52,7.1-8.71c1.79,0.15,3.55,0.62,5.31,0.97
	c10.27,2.03,12.71,0.29,14.2-10.05c0.11-0.76,0.52-1.48,0.79-2.23c0.56,0.67,1.31,1.26,1.64,2.03c3.29,7.8-1.05,14.06-10.1,14.66
	c-12.12,0.81-13.1,0.9-12.01,12.76c1.34,14.64-15.46,25.17-27.86,20.74c-5.13-1.83-10.41-3.4-14.42-7.27
	c-3.37-3.26-6.21-3.01-9.22,0.34c-2.27,2.52-4.84,4.18-8.55,4.39c-10.14,0.58-17.18-3.72-20.69-13.73c-1.59-4.53-3.01-7.9-8.74-4.83
	c-2.05,1.1-4.62,1.32-6.99,1.68c-6.92,1.04-14.16-4.71-14.54-11.49c-0.05-0.88,0.17-2.52,0.35-2.54c1.64-0.1,1.05,1.5,1.54,2.33
	c3.56,5.98,8.04,9.13,15.56,6.78c3.49-1.09,6.71-2.6,10.02-3.98c5.6-2.33,5.66-2.35,7.12,3.34c0.6,2.33,0.98,4.73,1.75,7
	c2.33,6.86,10.29,10.36,15.93,6.88c2.01-1.24,3.59-3.21,5.31-4.9c5.53-5.43,5.53-5.44,11.64-1c1.22,0.89,2.49,1.75,3.53,2.82
	c4.5,4.65,10.11,6.27,17.79,6.24C550.86,589.28,553.83,587.95,556.91,582.64z"></path>
                <path class="st4" d="M419.97,154.51c-3.6-1.84-6.06-1.87-7.58-0.95c-0.73,0.44-1.23,1.09-1.54,1.86c-0.3,0.75-0.41,1.62-0.36,2.51
	c0.14,2.18,1.3,4.53,3.15,5.73c-2.08,0.63-3.58,1.67-4.57,2.89c-0.95,1.17-1.44,2.5-1.57,3.82c-0.12,1.32,0.13,2.61,0.67,3.7
	c0.57,1.14,1.46,2.06,2.58,2.54l0.24-0.57c-0.98-0.42-1.76-1.23-2.27-2.24c-0.49-0.99-0.72-2.17-0.61-3.36
	c0.11-1.2,0.56-2.41,1.43-3.48c1.03-1.27,2.65-2.32,4.99-2.88l0.89-0.21l-0.83-0.38c-2.04-0.91-3.33-3.34-3.48-5.58
	c-0.05-0.8,0.05-1.58,0.31-2.24c0.26-0.65,0.68-1.2,1.28-1.56c1.34-0.81,3.6-0.76,6.97,0.97L419.97,154.51z"></path>
                <path class="st3" d="M383.72,702.05c0,0-13.71,14.34-1.73,24.07s23.85-0.99,23.85-0.99s-9.07,15.87,6.11,25.23
	c15.18,9.35,25.71-4.76,25.71-4.76s-2.64,14.68,11.32,18.99s19.4-5.22,19.4-5.22s-2.64,14.68,13.25,14.17
	c15.89-0.51,10.2-5.67,10.2-5.67s2.18,9.86,13.22,10.66c11.04,0.79,11.75-9.07,11.75-9.07s10.2,12.3,20.58,11.39
	c10.38-0.91,6.36-11.11,6.36-11.11s7.18,10.43,16.88,7.82c9.7-2.61,11.99-12.36,11.99-12.36s10.69,5.73,15.77,1.13
	c5.09-4.59,7.39-15.09,7.39-15.09"></path>
                <path class="st3" d="M621.91,538.37c0,0,18.03,0.6,17.26,8.63c-0.77,8.03-13.09,13.88-13.09,13.88s17.26-4.19,17.26,2.47
	c0,6.66-10.71,12.05-10.71,12.05s15.66,7.94,14.01,16.26s-14.63,7.09-14.63,7.09s18.16,4.32,14.75,11.94
	c-3.42,7.62-16.61,14.88-16.61,14.88s14.84,7.72,13.19,16.05c-1.65,8.33-15.12,5.8-15.12,5.8s20.06,12.52,15.12,19.19
	c-4.95,6.66-18.13,9.99-18.13,9.99s16.48,13.32,13.19,19.99c-3.3,6.66-16.48,7.15-16.48,7.15s13.19,12.21,8.24,17.52
	c-4.95,5.31-35.18,5.03-35.18,5.03"></path>
                <path class="st12" d="M578.65,162.04c0,7.63-46.04,20.29-102.73,20.29c-56.81,0-102.79-12.66-102.79-20.29
	c0-7.67,45.98-13.86,102.79-13.86C532.61,148.18,578.65,154.37,578.65,162.04z"></path>
                <path class="st13" d="M377.9,158.99c-1.46-6.47-0.45-1.43-0.45-8.32c0-52.27,43.68-94.61,97.52-94.61
	c53.89,0,97.57,42.34,97.57,94.61c0,6.89,1.07,1.11-0.39,7.58C516.11,176.03,451.36,176.28,377.9,158.99z"></path>
                <path class="st14" d="M458.14,55.65c46.43,7.26,81.93,46.31,81.93,93.41c0,5.08-0.45,10.08-1.23,14.97
	c10.32-2.03,20.58-5.13,31.01-5.82c0.34-0.15,0.67-0.29,1.01-0.42c0.28-2.87,0.45-5.78,0.45-8.74c0-52.27-43.68-94.61-97.52-94.61
	C468.46,54.45,463.25,54.87,458.14,55.65z"></path>
                <path class="st15" d="M461.7,165.84c0.09-47.54-5.8-77.92-17.68-91.14c-1.18-2.93-0.92-5.47,0.8-7.63
	c14.46-21.08,32.27-22.94,53.43-5.59c4.06,2.06,4.62,6.02,1.67,11.88c-9.66,23.79-14.56,54.61-14.7,92.48H461.7z"></path>
                <g>
                    <path class="st16" d="M572.07,158.12c-54.65,10.8-119.68,10.93-195.09,0.39C440.49,172.82,492.39,179.57,572.07,158.12z"></path>
                </g>
                <g id="ear-right">
                    <path class="st17" d="M537.61,87.27c-1.47-8.75-0.9-4.09,0-23.35c1.02-21.88,8.88-34.33,26.52-8.28c0.51,3.26,1.82,2.38,0,16.35
	c-3.4,16.36-17.95,27.74-17.95,27.74L537.61,87.27z"></path>
                    <path class="st18" d="M547.52,53.46c7.16,7.36,7.24,26.92,2.53,30.21c-1.98,1.38-5.19-11.08-5.47-21.87
	C544.3,51,547.52,53.46,547.52,53.46z"></path>
                </g>
                <path class="st17" d="M414.88,87.27c1.47-8.75,0.9-4.09,0-23.35c-1.02-21.88-8.88-34.33-26.52-8.28c-0.51,3.26-1.82,2.38,0,16.35
	c3.4,16.36,17.95,27.74,17.95,27.74L414.88,87.27z"></path>
                <path class="st18" d="M404.97,53.46c-7.16,7.36-7.24,26.92-2.53,30.21c1.98,1.38,5.19-11.08,5.47-21.87
	C408.19,51,404.97,53.46,404.97,53.46z"></path>
                <rect x="414.21" y="948.25" class="st19" width="125.28" height="10.46"></rect>
                <rect x="466.96" y="610.02" class="st13" width="18.13" height="338.24"></rect>
                <rect x="473.55" y="614.2" class="st20" width="4.95" height="333.92"></rect>
                <rect x="223" y="596.07" class="st21" width="511" height="19.18"></rect>
                <rect x="223" y="602.66" class="st22" width="511" height="4.95"></rect>
                <g>
                    <path class="st23" d="M528.74,595.42v0.79c-0.03,0.52-0.73,1.02-1.85,1.02H424.59c-1.12,0-1.82-0.5-1.85-1.02v-0.03
		c0-0.28,0.2-0.55,0.63-0.76H528.74z"></path>
                    <path class="st24" d="M526.89,597.28H424.59c-1.8,0-2.54-2.16-1.07-3.13l10.27-6.8c1.93-1.28,3.17-3.28,3.4-5.47l3.35-32.52h70.42
		l3.35,32.52c0.23,2.2,1.47,4.2,3.4,5.47l10.27,6.8C529.43,595.12,528.69,597.28,526.89,597.28z"></path>
                    <path class="st23" d="M651.58,315.26v225.41c0,4.69-3.81,8.5-8.5,8.5H308.4c-4.69,0-8.5-3.81-8.5-8.5V315.26
		c0-4.69,3.81-8.5,8.5-8.5h334.68C647.77,306.76,651.58,310.56,651.58,315.26z"></path>
                </g>
                <path class="st25" d="M575.1,503.06H378.52c-4.91,0-8.89-3.98-8.89-8.89V385.73c0-4.91,3.98-8.89,8.89-8.89H575.1
	c4.91,0,8.89,3.98,8.89,8.89v108.43C584,499.07,580.01,503.06,575.1,503.06z"></path>
                <path class="st26" d="M541.66,495.52H408.27c-4.91,0-8.89-3.98-8.89-8.89V415.4c0-4.91,3.98-8.89,8.89-8.89h133.39
	c4.91,0,8.89,3.98,8.89,8.89v71.23C550.56,491.53,546.58,495.52,541.66,495.52z"></path>
                <g id="tool">

                    <rect x="465.59" y="249.62" transform="matrix(0.9966 -0.0823 0.0823 0.9966 -19.1441 42.0314)" class="st27" width="69.36" height="7.24"></rect>
                    <path class="st28" d="M458.98,267.54l-59.59,4.92c-3.86,0.32-7.28-2.58-7.6-6.44l-0.75-9.1c-0.32-3.86,2.58-7.28,6.44-7.6
		l59.59-4.92c3.86-0.32,7.28,2.58,7.6,6.44l0.75,9.1C465.74,263.8,462.84,267.22,458.98,267.54z"></path>

                    <polygon class="st29" points="560.98,251.85 546.01,255.51 535.13,253.99 534.54,246.77 545.01,243.49 560.38,244.64 	"></polygon>
                    <path class="st30" d="M454.99,255.46l-49.44,4.08c-0.7,0.06-1.32-0.47-1.38-1.17l-0.14-1.66c-0.06-0.7,0.47-1.32,1.17-1.38
		l49.44-4.08c0.7-0.06,1.32,0.47,1.38,1.17l0.14,1.66C456.22,254.78,455.69,255.4,454.99,255.46z"></path>
                    <path class="st30" d="M455.59,262.67l-49.44,4.08c-0.7,0.06-1.32-0.47-1.38-1.17l-0.14-1.66c-0.06-0.7,0.47-1.32,1.17-1.38
		l49.44-4.08c0.7-0.06,1.32,0.47,1.38,1.17l0.14,1.66C456.82,261.99,456.29,262.62,455.59,262.67z"></path>
                </g>
                <path class="st8" d="M453.77,243.31c0-3.75,34.62,2.85,34.62,6.59s-8.71,1.35-17.41,1.35C462.27,251.26,453.77,247.06,453.77,243.31
	z"></path>
                <path class="st8" d="M490.04,258.15c0,3.75-33.48,14.12-33.48,10.38c0-3.75,5.65-6.78,12.62-6.78S490.04,254.4,490.04,258.15z"></path>
                <g>
                    <polygon class="st28" points="473.3,98.2 449.2,98.2 443.5,110.9 473.3,139.7 503.1,110.9 497.4,98.2 		"></polygon>
                    <path class="st31" d="M478.8,101.3h-0.6c-1.7-0.1-3.4,0.5-4.8,1.5c-1.4-1.1-3-1.6-4.8-1.5h-0.7c-4.9,0-7.7,2.3-7.7,7.2v12.4
			c0,0.4,0.3,0.8,0.7,0.8h4.3c0.4-0.1,0.7-0.4,0.7-0.8v-12.4c0-2,0.6-2.6,2-2.6h0.7c1.8,0,2,1,2,2.6v12.4c0,0.4,0.3,0.8,0.7,0.8h4.3
			c0.4-0.1,0.7-0.4,0.6-0.8v-12.4c0-1.5,0.2-2.6,2-2.6h0.6c1.5,0,2,0.6,2,2.6v12.4c0,0.4,0.3,0.8,0.7,0.8h4.3
			c0.4-0.1,0.7-0.4,0.7-0.8v-12.4C486.5,103.6,483.7,101.3,478.8,101.3z"></path>
                    <polygon class="st32" points="491.9,98.2 498.3,115.5 503.1,110.9 497.4,98.2 		"></polygon>
                </g>
            </svg>
        </div>
    </div>
</template>
<script>
    import BasePage from "@/pages/BasePage";
    import { userStore } from '@development/cluster-center';

    export default {
        extends: BasePage,
        name: 'MaintenancePage',
        props: {
            hideLogo: Boolean
        },
        data() {
            return {
                userStore: userStore(),
            };
        },
        mounted() {
            let pageWrapper = document.querySelector('.outerWrapper');
            pageWrapper.classList.add('errorPage');
        },
        unmounted() {
            let pageWrapper = document.querySelector('.outerWrapper');
            pageWrapper.classList.remove('errorPage');
        }
    };
</script>
<style scoped>
    /* stylelint-disable */
    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#E8E8E8;}
    .st1{fill:#D8D8D9;}
    .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#3E3A39;}
    .st3{fill-rule:evenodd;clip-rule:evenodd;fill:#EDEDED;}
    .st4{fill-rule:evenodd;clip-rule:evenodd;fill:#C8C9CA;}
    .st5{opacity:0.5;fill:#B5B5B5;enable-background:new    ;}
    .st6{fill:#B1B1B1;}
    .st7{fill:#202021;}
    .st8{fill:#8D8C8C;}
    .st9{fill:#282829;}
    .st10{fill:#343336;}
    .st11{opacity:0.5;fill:#B4B4B4;enable-background:new    ;}
    .st12{fill:#FF7347;}
    .st13{fill:#FF7B43;}
    .st14{fill:#FF8964;}
    .st15{fill:#FF6A2B;}
    .st16{fill:#AF2E0B;}
    .st17{fill-rule:evenodd;clip-rule:evenodd;fill:#DDDDDD;}
    .st18{fill-rule:evenodd;clip-rule:evenodd;fill:#AFAFAF;}
    .st19{fill:#E56B41;}
    .st20{fill:#FF7F52;}
    .st21{fill:#FF8564;}
    .st22{fill:#FF9064;}
    .st23{fill:#686868;}
    .st24{fill:#4F4F4F;}
    .st25{fill:#515151;}
    .st26{fill:#3F3F3F;}
    .st27{fill:#C1C1C1;}
    .st28{fill:#E24B17;}
    .st29{fill:#C6C6C6;}
    .st30{fill:#BF401F;}
    .st31{fill:#D31216;}
    .st32{fill:#E85831;}
</style>
<style lang="scss">
.errorPage {
    overflow: hidden;
    height: 100vh;

    .lead {
        font-weight: 500;
        font-size: 1.15rem;
        line-height: 1.725rem;
    }

    .sublead {
        color: $lama-gray;
    }

    #footer {
        margin-top: 4rem !important;
    }

    #content {
        max-height: 87vh;
    }

    .container .row.center {
        height: 100vh;
        display: flex;
        align-items: center;
    }

    $cubic: cubic-bezier(0.785, 0.135, 0.15, 0.86);

    #alpaka svg{
        overflow: visible;
        transform-box:fill-box;
        transform: translate(-375px, 200px);

        #eye {
            animation: blink 6s $cubic infinite;
            transform-origin: bottom;
            transform-box:fill-box;
        }

        #ear-right{
            animation: leftEar;
            animation-duration: 1s;
            transform-origin: bottom left;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-direction: alternate;
            transform-box:fill-box;
        }

        #tool {
            animation: toolRotate;
            animation-duration: 2s;
            transform-origin: center;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in;
            animation-direction: alternate;
            transform-box:fill-box;
        }

        #tail{
            transform-box: fill-box;
            animation: tail;
            animation-duration: .75s;
            transform-origin: bottom left;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in;
            animation-direction: alternate;
        }
    }

    @keyframes blink {
        49% {
            transform: scaleY(1);
        }
        50% {
            transform: scaleY(0.125);
        }
        51% {
            transform: scaleY(1);
        }
        100% {
            transform: scaleY(1);
        }
    }

    @keyframes tail{
        0%{
            transform: rotate(-5.5deg);
        }
        100%{
            transform: rotate(5.5deg);
        }
    }
    @keyframes leftEar{

        0%{
            transform: rotate(-7.5deg);
        }
        60%{
            transform: rotate(7.5deg);
        }
        100%{
            transform: rotate(0deg);
        }
    }

    @keyframes toolRotate{
        0%{
            transform: rotate(-1.3deg);
        }
        100%{
            transform: rotate(1.3deg);
        }
    }

    $mc: 0;
    @keyframes mouth{
        0%{
            transform: translate($mc, 0);
        }
        25%{
            transform: translate($mc, $mc) scale(1.25) ;
        }
        50%{

            transform: translate(-$mc, $mc);
        }
        75%{
            transform: translate(-$mc, 0) scale(.85);
        }
        100%{
            transform: translate($mc, 0) ;

        }
    }

    .message {
        margin-top: -12rem;
    }
}
</style>