export default  {
    de:{
        owner_handle_could_not_be_retrieved : 'Der Owner-Handle konnte nicht abgerufen werden.',
        owner_handle_taxcode_required_for_private_person_for_se_registrar : 'Der Owner-Handle muss für eine Privatperson eine Steuernummer enthalten im Falle einer Domain mit .se-TLD.',
        owner_handle_commercial_register_required_for_company_for_se_registrar : 'Der Owner-Handle muss für ein Unternehmen eine Handelsregisternummer enthalten im Falle einer Domain mit .se-TLD.',
    },
    en:{
        owner_handle_could_not_be_retrieved: 'The owner handle could not be retrieved.',
        owner_handle_taxcode_required_for_private_person_for_se_registrar: 'The owner handle must contain a tax code for a private person in the case of a domain with a .se TLD.',
        owner_handle_commercial_register_required_for_company_for_se_registrar: 'The owner handle must contain a commercial register number for a company in the case of a domain with a .se TLD.',
    }
};