export default {
    de: {
        company_data: 'Unternehmensdaten',
        contact_person: 'Ansprechpartner',
        accounting_and_bookkeeping: 'Rechnungswesen und Buchhaltung',
        payment_information: 'Zahlungsinformationen',
        form: {
            company: 'Unternehmen',
            department: 'Abteilung',
            street: 'Straße und Hausnummer',
            postcode: 'PLZ',
            city: 'Stadt',
            country: 'Land',
            phone: 'Telefon(Zentrale)',
            language: 'Sprache',
            tax_id: 'USt-ID',
            reference: 'Bezugszeichen',
            general: 'Allgemein',
            new_customer_entry_date: 'Neukunden Eintrittsdatum',
            email_for_invoice: 'E-Mail-Adresse für Rechnungen',
            multiple_emails: 'Mehrere E-Mail-Adressen können durch einen Zeilenumbruch oder ein "," getrennt werden.',
            email_for_reminder: 'E-Mail-Adressen für Mahnungen',
            multiple_reminder: 'Ist dieses Feld leer, werden Mahnungen an die hinterlegten E-Mail-Adressen für Rechnungen gesendet. Mehrere E-Mail-Adressen können durch einen Zeilenumbruch oder ein "," getrennt werden.',
            success: 'Sie haben die Daten erfolgreich geändert.'
        },
        payment: {
            variant: 'Zahlungsweise',
            owner: 'Kontoinhaber',
            iban: 'IBAN',
            bic: 'BIC',
            number: 'Nummer',
            types: {
                transfer: 'Überweisung',
                debit: 'Lastschrift',
                credit_card: 'Kreditkarte',
                creditcard: 'Kreditkarte',
            },
            reference: 'Referenz',
        },
        documents: {
            label: 'Dokumente|Dokument|Dokumente',
            customer_documents_title: 'Kundenbezogene Dokumente - {number} {company}',
            general_documents: 'Allgemeine Dokumente',
            search: 'Dokumente',
            no_files: 'Es sind keine Dokumente vorhanden.',
            validity: 'Gültigkeit',
            cannot_load_customer_documents: 'Die Kundenbezogenen Dokumente konnten nicht geladen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder kontaktieren Sie unseren Service (<a href="mailto:{email}">{email}</a> oder <a href="tel:{phone}">{phone}</a>).',
            cannot_load_general_documents: 'Die allgemeinen Dokumente konnten nicht geladen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder kontaktieren Sie unseren Service (<a href="mailto:{email}">{email}</a> oder <a href="tel:{phone}">{phone}</a>).',
            cannot_download: 'Das angeforderte Dokument konnte nicht heruntergeladen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder kontaktieren Sie unseren Service (<a href="mailto:{email}">{email}</a> oder <a href="tel:{phone}">{phone}</a>).',
        },
        dpa: {
            title_warning: 'Dringend: Auftragsverarbeitungsvertrag (AVV) abschließen!',
            title_contract: 'AV-Vertrag abschließen - {number} {company}',
            title_warning_expired: 'Aktuellen Auftragsverarbeitungsvertrag (AVV) abschließen!',
            success: 'AVV erfolgreich abgeschlossen',
            description_warning: 'Wir verarbeiten personenbezogene Daten in Ihrem Auftrag. Aufgrund gesetzlicher Vorschriften (Art. 28 DSGVO) ist es deshalb notwendig, dass wir mit Ihnen einen Vertrag zur Auftragsverarbeitung abschließen. Ohne Abschluss des Vertrags dürfen wir leider keine weiteren Datenverarbeitungen für Sie durchführen.<br><br>Den Vertrag sowie die dazugehörigen Anlagen finden Sie hier:',
            description_warning_expired: 'Wir haben unseren Auftragsverarbeitungsvertrag (AVV) zeitgemäß aktualisiert. Die aktuelle Version unseres AV-Vertrags sowie die dazugehörigen Anlagen finden Sie hier.',
            description_contract: 'Unter den folgenden Links können Sie unseren Auftragsverarbeitungsvertrag (AVV) mit allen Vertragsbestandteilen einsehen. Der Vertrag wird Ihnen per E-Mail zugesendet, sobald Sie ihn bestätigt und abgesendet haben.',
            description_conclude: 'Der Vertrag wird elektronisch erstellt und ist ohne Unterschrift gültig.',
            table: {
                document: 'Dokument',
                valid_since: 'Gültig seit',
            },
            complete_contract: 'Ich möchte den AVV mit der maxcluster GmbH abschließen',
            complete: 'Abschließen',
            label: 'AV-Vertrag',
        },
        dpa_completed: {
            title: 'AVV erfolgreich abgeschlossen',
            description: 'Sie haben den AV-Vertrag für die Kundennummer {number} erfolgreich abgeschlossen. Der Vertrag wurde allen Nutzern mit der Berechtigung "Manager" per E-Mail zugesendet. Der AVV kann außerdem jederzeit in Ihrem Dokumentenbereich heruntergeladen werden.',
            download: 'AV-Vertrag herunterladen'
        },
        export: {
            error: 'Beim Exportieren der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Service (<a href="mailto:{email}">{email}</a> oder <a href="tel:{phone}">{phone}</a>).',
        }
    },
    en: {
        company_data: 'Company data',
        contact_person: 'Contact person',
        accounting_and_bookkeeping: 'Accounting and bookkeeping',
        payment_information: 'Payment information',
        form: {
            company: 'Company',
            department: 'Department',
            street: 'Street and house number',
            postcode: 'Zip',
            city: 'City',
            country: 'Country',
            phone: 'Telephone (central office)',
            language: 'Language',
            tax_id: 'VAT ID',
            reference: 'Reference sign',
            general: 'General',
            new_customer_entry_date: 'New customer entry date',
            email_for_invoice: 'Email address for invoices',
            multiple_emails: 'Multiple email addresses can be separated by a line break or a ",".',
            email_for_reminder: 'Email addresses for reminders',
            multiple_reminder: 'If this field is empty, reminders will be sent to the stored e-mail addresses for invoices. Multiple e-mail addresses can be separated by a line break or a ",".',
            success: 'The data had been changed successfully.'
        },
        payment: {
            variant: 'Payment method',
            owner: 'Account holder',
            iban: 'IBAN',
            bic: 'BIC',
            number: 'Number',
            types: {
                transfer: 'Transfer',
                debit: 'Debit',
                credit_card: 'Credit card',
                creditcard: 'Credit card'
            },
            reference: 'Reference',
        },
        documents: {
            label: 'Documents|Document|Documents',
            customer_documents_title: 'Customer-related documents - {number} {company}',
            general_documents: 'General documents',
            search: 'Documents',
            no_files: 'There are no documents available.',
            validity: 'Validity',
            cannot_load_customer_documents: 'The customer-related documents could not be loaded. Please try again later or contact our service (<a href="mailto:{email}">{email}</a> or <a href="tel:{phone}">{phone}</a>).',
            cannot_load_general_documents: 'The general documents could not be loaded. Please try again later or contact our service (<a href="mailto:{email}">{email}</a> or <a href="tel:{phone}">{phone}</a>).',
            cannot_download: 'The requested document could not be downloaded. Please try again later or contact our service (<a href="mailto:{email}">{email}</a> or <a href="tel:{phone}">{phone}</a>).',
        },
        dpa: {
            title_warning: 'Urgent: Conclude our Data Processing Agreement (DPA)!',
            title_warning_expired: 'Conclude updated data processing agreement (DPA)!',
            title_contract: 'Conclude DPA - {number} {company}',
            success: 'DPA successfully concluded',
            description_warning: 'We process personal data on your behalf. Due to legal requirements (Art. 28 GDPR), it is therefore imperative that we enter into a Data Processing Agreement with you. Without concluding the agreement, we are unfortunately not allowed to carry out any further data processing for you.<br><br>You can find the contract and the associated annexes here.',
            description_warning_expired: 'We have updated our Data Processing Agreement (DPA) to keep pace with the times. You can find the current version of our DPA and its associated annexes here.',
            description_contract: 'Under the following links, you can review our Data Processing Agreement (DPA) with all associated annexes. The DPA will be sent to you by e-mail as soon as you have confirmed and sent it.',
            description_conclude: 'The agreement is generated electronically and does not require a signature.',
            table: {
                document: 'Document',
                valid_since: 'Valid since',
            },
            complete_contract: 'I agree to conclude the DPA with maxcluster GmbH',
            complete: 'Confirm',
            label: 'DPA'
        },
        dpa_completed: {
            title: 'DPA successfully concluded',
            description: 'You have successfully concluded the Data Processing Agreement for customer number {number}. The DPA was sent by e-mail to all users with the authorization "Manager". The DPA is also available for download in your document area at any time.',
            download: 'Download DPA'
        },
        export: {
            error: 'An error occurred while exporting the data. Please try again later or contact our service (<a href="mailto:{email}">{email}</a> or <a href="tel:{phone}">{phone}</a>).',
        }
    }
};