export default [
    {
        path: '/invoice/public/:token',
        name: 'invoices-public-details',
        component: () => import(/* webpackChunkName: "Accounting" */ "@/pages/Accounting/Invoices/InvoiceDetailPage.vue"),
        meta: {
            title: (i18n, invoice) => {
                return i18n.t('app.page_title.invoice_details', {number: invoice?.number, status: i18n.t('accounting.invoice.status.' + invoice?.status?.toLowerCase())?.toLowerCase()});
            },
        },
    }
];