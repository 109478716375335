<template>
    <div role="banner" class="bg-placement row d-flex pt-3" :class="isInDemoMode === false ? 'always-visible' : ''" v-bind="$attrs">
        <div class="col-7 d-flex align-items-center">
            <div class="row support-row">
                <div class="col">
                    <strong class="support-notify"> Support (24/7/365)</strong>
                </div>
                <div class="col">
                    <AtomTextLink :href="`tel:${$cc.support.phone}`">
                        <AtomIcon name="phone-handset-line" /> {{ $cc.support.phone_formatted }} (24/7)
                    </AtomTextLink>
                </div>
                <div class="col">
                    <AtomTextLink :href="`mailto:${$cc.support.email}`">
                        <AtomIcon name="envelope-line" /> {{ $cc.support.email }} (08:00-22:00 Uhr)
                    </AtomTextLink>
                </div>
            </div>
        </div>
        <div class="col-5 lang-select d-flex justify-content-end">
            <SwitchDesign v-if="!isInDemoMode && user.isLoggedIn" class="me-2" />
            <AtomFilter class="language-switch" :empty-result="false" @update:filters="changeLocale" :filters="languages" :single="true" v-model="selectedLanguage" />
        </div>
        <div class="col-12">
            <hr class="nav-divider mb-1" />
        </div>
    </div>
    <div :class="(user.isLoggedIn === true && !isInDemoMode) ? '' : 'd-none'" class="sticky-top mb-4 nav-wrapper">
        <div :class="user.isLoggedIn ? 'context-container-nav' : 'bg-placement'" class="ms-0 me-0 row mb-3 autohide">
            <div class="col-12">
                <OrganismNavbar toggleable="xl" class="main-navigation" id="main-nav">
                    <template #navbar-brand>
                        <router-link to="/" class="navbar-brand me-4 ms-0">
                            <template v-if="inDarkMode">
                                <AtomImage class="d-flex align-items-center" :size="174" :src="require('@/assets/images/logo/maxcluster-white.svg')" alt="maxcluster-logo" draggable="false" />
                            </template>
                            <template v-else>
                                <AtomImage class="d-flex align-items-center" :size="174" :src="require('@/assets/images/logo/logo.svg')" alt="maxcluster-logo" draggable="false" />
                            </template>
                        </router-link>
                    </template>
                    <template v-if="user.isLoggedIn && !isInDemoMode">
                        <AtomNavbarItem :to="{ name: 'dashboard' }">{{ $t('app.navigation.dashboard') }}</AtomNavbarItem>
                        <AtomNavbarItem :to="{ name: 'contracts' }">{{ $t('app.navigation.contracts') }}</AtomNavbarItem>
                        <AtomNavbarItem :to="{ name: 'invoices-overview' }">{{ $t('app.navigation.invoices') }}</AtomNavbarItem>
                        <AtomNavbarItem :to="{ name: 'orders-list' }">{{ $t('app.navigation.orders') }}</AtomNavbarItem>
                        <AtomNavbarItem :to="{ name: 'partner' }" v-if="isPartner">{{ $t('app.navigation.partners.title') }}</AtomNavbarItem>
                    </template>
                    <template #right-navbar v-if="user.isLoggedIn && !isInDemoMode">
                        <NotificationToggle/>
                        <AtomAvatar>
                            <AtomDropdown style-variant="secondary" end :text="$cc.initials(name.firstname, name.surname)">
                                <AtomDropdownItem :to="{name: 'profile-personal-data'}">
                                    {{ $t('app.navigation.profile.my_data') }}
                                </AtomDropdownItem>
                                <AtomDropdownItem class="customDesign">
                                    <ChangeCustomizationForm inMenu/>
                                </AtomDropdownItem>
                                <AtomDropdownItem class="danger" :to="{name: 'logout'}">
                                    {{ $t('app.navigation.profile.logout') }}
                                </AtomDropdownItem>
                            </AtomDropdown>
                        </AtomAvatar>
                    </template>
                </OrganismNavbar>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { userStore, EventHandler } from '@development/cluster-center';
    import SwitchDesign from "@/components/SwitchDesign.vue";
    import NotificationToggle from "@/components/Notification/NotificationToggle.vue";
    import ChangeCustomizationForm from './Accounting/Profile/Forms/ChangeCustomizationForm.vue';
    import {computed, inject, onMounted, ref, watch} from 'vue';
    import {useRoute} from "vue-router";

    const $cc = inject('cc');
    const i18n = window.Config.get('i18n');
    const props = defineProps({
        languages: {
            type: Array,
            default: () => []
        }
    });

    const user = userStore();
    const selectedLanguage = ref([i18n.locale]);
    const isManagedRoute = ref(false);

    const route = useRoute();

    onMounted(() => {
        if (document.querySelector('.always-visible')) {
            document.querySelector('.sticky-top').style.height = document.querySelector('.always-visible').scrollHeight + 'px';
        }

        if (route?.name && route?.name.includes('managed-')) {
            isManagedRoute.value = true;
        }
    });

    const isPartner = computed(() => {
        return user.me.is_partner;
    });

    const inDarkMode = computed(() => {
        return user.darkMode;
    });

    const name = computed(() => {
        if (
            user &&
            user.firstname &&
            user.surname
        ) {
            return {
                firstname: user.firstname,
                surname: user.surname
            };
        }

        return { firstname: 'Max', surname: 'Cluster' };
    });

    const changeLocale = locale => {
        if (!locale) {
            locale = ['de'];
        }

        if (!Array.isArray(locale)) {
            locale = [locale];
        }

        i18n.locale = locale[0];
        selectedLanguage.value = locale;

        EventHandler.dispatch('app:updated:locale');
    };

    watch(selectedLanguage, val => {
        user.$patch({lang: val[0]});
    });

    watch(i18n.locale, locale => {
        selectedLanguage.value = [locale];
    });

    watch(() => props.languages, val => {
        if (!Array.isArray(val)) {
            return;
        }

        val.forEach(language => {
            if (language.active) {
                selectedLanguage.value = [language.name];
            }
        });
    }, { deep: true });

    watch(() => route.name, val => {
        isManagedRoute.value = val.toLowerCase().includes('managed-');
    });

    const isInDemoMode = computed(() => {
        return window?.Config?.isDemo() || false;
    });
</script>
<style lang="scss">
    .lang-select .btn-group {
        .btn {
            text-transform: uppercase;
            padding: .5rem 1.5rem;
        }

        .btn-secondary.active {
            background: $secondary !important;

            &:focus,
            &:active {
                border-color: $secondary;
            }
        }
    }

    .customDesign {

        .dropdown-item {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }

    .customDesign:hover {
        background-color: $white !important;
        color: $black;
        cursor: default;

        button {
            background-color: $white !important;
            color: $black;
            cursor: default;

            p {
                color: $black;
            }
        }
    }



    .support-row {
        font-size: 14px;
        flex-wrap: nowrap;

        .col {
            flex: 0 0 auto;
            width: auto;
        }

        .atom-icon {
            font-size: 1.2rem;
            transform: translateY(4px);
            margin-right: .2rem;
        }

        a {
            text-decoration: none;
        }
    }

    #main-nav {
        .atom-avatar {
            .btn {
                color: $primary;
                border-color: $primary;
            }

            .atom-dropdown ul.dropdown-menu {
                max-height: 600px !important;
            }
        }

        &.is-collapsed {
            .navbar-collapse {
                margin-block-start: 1rem;
            }

            .navbar-nav {
                gap: .75rem;
            }

            .notificationWrapper,
            .atom-avatar {
                margin-block-start: 1rem;
                margin-block-end: .5rem;
                display: inline-flex;
            }

            .atom-avatar {
                margin-inline-start: .5rem;
            }
        }
    }

    /* stylelint-disable color-no-hex */
    .nav-divider {
        background: #F0F0F0;
        border-color: #F0F0F0;
    }

    .scrolled-down {
        transform: translateY(-150%);
        transition: all .3s ease-in-out;
    }

    .scrolled-up {
        transform: translateY(0);
        transition: all .3s ease-in-out;
    }

    #content-area {
        transition: padding .3s ease-in-out;
    }

    .always-visible {
        z-index: 2;
        position:relative;
        background: $white;
    }

    .autohide {
        background: $white;
    }

    .support-notify {
        transform: translateY(2px);
        display: inline-block;
    }

    .language-switch {
        align-self: flex-start;
    }
</style>