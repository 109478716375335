export const iubendaConfiguration = {
    askConsentAtCookiePolicyUpdate: true,
    countryDetection: true,
    enableFadp: true,
    enableTcf: true,
    floatingPreferencesButtonDisplay: "bottom-left",
    googleAdditionalConsentMode: true,
    lang: "de",
    perPurposeConsent: true,
    promptToAcceptOnBlockedElements: true,
    siteId: process.env.VUE_APP_COOKIE_POLICY_SITE_ID || 123456,
    tcfPurposes: {
        2: "consent_only",
        7: "consent_only",
        8: "consent_only",
        9: "consent_only",
        10: "consent_only",
        11: "consent_only"
    },
    whitelabel: false,
    cookiePolicyId: process.env.VUE_APP_COOKIE_POLICY_ID || 123456,
    cookiePolicyUrl: "https://maxcluster.de/embedded/datenschutz#m134",
    banner: {
        acceptButtonCaptionColor: "#FFFFFF",
        acceptButtonColor: "#D21319",
        acceptButtonDisplay: true,
        backgroundColor: "#FFFFFF",
        backgroundOverlay: false,
        brandBackgroundColor: "#FFFFFF",
        brandTextColor: "#212121",
        closeButtonDisplay: false,
        customizeButtonCaptionColor: "#4D4D4D",
        customizeButtonColor: "#DADADA",
        customizeButtonDisplay: true,
        explicitWithdrawal: true,
        listPurposes: true,
        logo: "https://maxcluster.de/user/pages/images/nav/logo.svg",
        linksColor: "#D21319",
        position: "float-top-center",
        rejectButtonCaptionColor: "#FFFFFF",
        rejectButtonColor: "#D21319",
        rejectButtonDisplay: true,
        showPurposesToggles: true,
        textColor: "#212121"
    }
};
