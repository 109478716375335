import { userStore } from '@development/cluster-center';
import { useColorMode } from 'bootstrap-vue-next';


export default [
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "Auth" */ "@/pages/Auth/LoginPage.vue"),
        meta: {
            title: i18n => i18n.t('app.page_title.login'),
            middleware: ['isAuthenticated'],
        },
        props: {
            type: 'login'
        }
    },
    {
        path: "/password/reset/:token",
        name: "password-reset",
        component: () => import(/* webpackChunkName: "Auth" */ "@/pages/Auth/LoginPage.vue"),
        meta: {
            title: i18n => i18n.t('app.page_title.reset_password'),
            middleware: ['isAuthenticated'],
        },
        props: {
            type: 'password-reset'
        }

    },
    {
        path: "/password/forgot",
        name: "password-forgot",
        component: () => import(/* webpackChunkName: "Auth" */ "@/pages/Auth/LoginPage.vue"),
        meta: {
            title: i18n => i18n.t('app.page_title.forgot_password'),
            middleware: ['isAuthenticated'],
        },
        props: {
            type: 'password-forgot'
        }
    },
    {
        path: "/registration/:type/:id/:email/:token",
        name: "register-user",
        component: () => import(/* webpackChunkName: "Auth" */ "@/pages/Auth/RegisterUserPage.vue"),
        meta: {
            title: i18n => i18n.t('app.page_title.register'),
            middleware: ['isAuthenticated'],
        }
    },
    {
        path: "/invitation/:type/:token/:email/:id",
        name: "invitation",
        component: () => import(/* webpackChunkName: "Auth" */ "@/pages/Auth/InvitationPage.vue"),
        meta: {
            title: i18n => i18n.t('app.page_title.invitation'),
            middleware: ['isAuthenticated'],
        }
    },
    {
        path: "/impersonate-two-factor-challenge/:userId/:token",
        name: "impersonate",
        component: () => import(/* webpackChunkName: "OpsAuth" */ "@/pages/Auth/ImpersonateUser.vue"),
        meta: {
            title: i18n => i18n.t('app.page_title.impersonate'),
            middleware: [],
        }
    },
    {
        path: "/logout",
        name: "logout",
        component: {
            async beforeRouteEnter(to, from, next) {
                const store = userStore();
                await store.logout(true);

                let target = document.querySelector('html');

                let mode = useColorMode({
                    selector: target,
                });

                mode.value = '';

                next({name: 'login'});
            }
        }
    },
];