<template>
    <PartnerHeaderLine />
    <div class="row">
        <div class="col-12 mt-2 mb-2">
            <h4>{{ $t('partner.already_payed_commission') }}</h4>
        </div>
        <div class="col-12 mt-2">
            <MoleculeLoader size="medium" v-if="actionIsRunning" />
            <p v-else-if="provisions.length === 0">{{ $t('partner.no_payouts') }}</p>
            <OrganismAutoSortableTable
                class="cluster-contracts-table"
                :data="provisions"
                :configuration="tableConfiguration"
                pagination
                :id="[`${userId}-partner-payout-table`]"
                configurable
                :configure-reset-button-text="$t('helper.actions.reset')"
                :configure-title-text="$t('helper.labels.hide_and_show_columns')"
                :configure-tooltip-text="configureTooltipText"
                @updated:config="hiddenColumns = $event.hidden"
                v-else
                server-side
                :initial-sorting-key="sortBy"
                :initial-sorting-direction="sortDir"
                :total-rows="total"
                @update:perPage="perPage = $event"
                @updated:page="page = $event"
                :page="page"
                @updated:sort="updateSorting"
            >
                <template #column-creation_date="provision">
                    <template v-if="provision.planned_invoice_date">
                        {{ $d(provision.planned_invoice_date.date, 'short') }}
                    </template>
                    <template v-else>
                        -
                    </template>
                </template>
                <template #column-payout_date="provision">
                    <template v-if="provision.invoicedAt">
                        {{ $d(provision.invoicedAt.date, 'short') }}
                    </template>
                    <template v-else>-</template>
                </template>
                <template #column-intended_purpose="provision">
                    <template v-if="provision.transactions && provision.transactions.length > 0">
                        <template v-if="lastTransaction(provision).status === 'finished'">
                            {{ lastTransaction(provision).reference }}
                        </template>
                        <template v-else>-</template>
                    </template>
                    <template v-else>-</template>
                </template>
                <template #column-amount="provision">
                    <template v-if="provision.amountNet < 0">
                        {{ $n(provision.amountNet * -1, 'currency') }}
                    </template>
                    <template v-else>
                        {{ $n(provision.amountNet, 'currency') }}
                    </template>
                </template>
                <template #column-status="provision">
                    <AtomBadge size="sm" variant="success" v-if="provision.status === 'cleared'">{{ $t('accounting.invoice.status.cleared') }}</AtomBadge>
                    <AtomBadge size="sm" variant="info" v-else>{{ $t('accounting.invoice.status.open') }}</AtomBadge>
                </template>
                <template #column-menu="provision">
                    <AtomButton variant="icon" @click.prevent="downloadProvision(provision)">
                        <AtomIcon name="download-line" />
                    </AtomButton>
                </template>
            </OrganismAutoSortableTable>
        </div>
    </div>
</template>
<script>
    import PartnerService from "@/services/Api/Accounting/PartnerService";
    import {Logger, InvoiceService, ResponseService, userStore} from "@development/cluster-center";
    import PartnerHeaderLine from "@/components/Accounting/Partner/PartnerHeaderLine.vue";
    import BaseComponent from "@/components/BaseComponent.vue";

    export default {
        emits: ['route:params:changed'],
        extends: BaseComponent,
        components: {PartnerHeaderLine},
        name: 'PartnerPayouts',
        data() {
            return {
                sort_by: 'id.desc',
                provisions: [],
                perPage: 10,
                page: 1,
                total: 0,
                customerId: 0,
                partnerId: 0,
                hiddenColumns: [],
                user: null
            };
        },
        computed: {
            sortBy() {
                if (this.sort_by !== '') {
                    return this.sort_by.split('.')[0];
                }

                return '';
            },
            sortDir() {
                if (this.sort_by !== '') {
                    return this.sort_by.split('.')[1];
                }

                return '';
            },
            userId() {
                return this.user.id;
            },
            configureTooltipText() {
                return window.Config.get('i18n').tc('helper.labels.hide_columns', this.hiddenColumns.length);
            },
            tableConfiguration() {
                const i18n = window.Config.get('i18n');

                return [
                    {key: 'number', sortBy: 'id', sortable: true, title: i18n.t('partner.commission_credit')},
                    {key: 'status', sortable: true, title: i18n.t('partner.status')},
                    {key: 'creation_date', title: i18n.t('partner.creation_date')},
                    {key: 'payout_date', sortBy: 'invoiced_at', sortable: true, title: i18n.t('partner.payout_date')},
                    {key: 'intended_purpose', title: i18n.t('partner.intended_purpose')},
                    {key: 'amount', sortable: true, title: i18n.t('partner.amount')},
                    {key: 'menu', title: '', width: 55, center: true},
                ];
            }
        },
        created() {
            this.customerId = this.$route.params?.customerId || 0;
        },
        mounted() {
            this.user = userStore();
            this.initialize();
        },
        methods: {
            updateSorting(val) {
                if (val.sort_by && val.sort_dir) {
                    this.sort_by = Object.values(val).join('.');
                    return;
                }

                this.sort_by = '';
            },
            async initialize() {
                const customers = await this.getCustomersData();

                if (!customers || !Array.isArray(customers)) {
                    return;
                }

                if (customers.length > 0) {
                    this.partnerId = customers[0].id;
                    this.customerId = customers[0]?.customer?.id || 0;
                }

                if (this.$route.params.customerId) {
                    this.customerId = this.$route.params.customerId;
                }

                if (this.$route.params.partnerId) {
                    this.partnerId = this.$route.params.partnerId;

                    if (!this.customerId && customers?.length > 0) {
                        this.customerId = customers.find(partner => partner.id === parseInt(this.$route.params.partnerId)).customer.id;
                    }
                }

                this.actionIsRunning = true;
                await this.getProvisions(1);
            },
            async getProvisions(page = 1) {
                if (Number.isNaN(this.partnerId)) {
                    this.partnerId = 0;

                    if (this.$route.params.partnerId) {
                        this.partnerId = this.$route.params.partnerId;
                    }
                }

                const params = {
                    customer_id: parseInt(this.customerId || 0),
                    type: 'provision',
                    not_invoice_items: 1,
                    include_transactions: 1,
                    sort_by: this.sort_by,
                    status: 'cleared',
                    per_page: this.perPage,
                    page: page
                };

                return await PartnerService.getProvisions(params, this.partnerId).then(response => response).then(response => {
                    if (Array.isArray(response)) {
                        if (response[2] !== 401) {
                            ResponseService.danger(this.$i18n.t(response[1], {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }));
                        }

                        return;
                    }
                    this.provisions = response?.data || [];
                    this.total = response?.original?.data?.meta?.total || this.provisions.length;

                    this.$emit('route:params:changed', {partnerId: this.partnerId, customerId: this.customerId});
                    this.actionIsRunning = false;
                });
            },
            lastTransaction(provision) {
                return provision.transactions[provision.transactions.length - 1];
            },
            async getCustomersData() {
                const i18n = window.Config.get('i18n');

                return await PartnerService.getMine().then(response => {
                    if (Array.isArray(response)) {
                        if (response[2] !== 401) {
                            return ResponseService.danger(i18n.t(response[1], {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }));
                        }

                        return;
                    }

                    return response.data;
                }).catch(e => {
                    Logger.log(e);
                });
            },
            async downloadProvision(provision) {
                await InvoiceService.getPdf(provision.id).then(response => {
                    if (Array.isArray(response)) {
                        if (response[2] === 401) {
                            return;
                        }

                        return ResponseService.danger(this.$i18n.t(response[1], {phone: this.$cc.support.phone_formatted, email: this.$cc.support.email }));
                    }

                    window.downloadPdf(response.data, `${provision.number}.pdf`);
                });
            }
        },
        watch: {
            '$route.params.customerId': {
                handler(id) {
                    this.customerId = parseInt(id);
                    this.initialize();
                }
            },
            '$route.params.partnerId': {
                handler(id) {
                    this.partnerId = parseInt(id);
                    this.initialize();
                }
            },
            page(val) {
                this.getProvisions(val);
            },
            perPage() {
                this.getProvisions(1);
            },
            sort_by() {
                this.getProvisions(1);
            }
        }
    };
</script>